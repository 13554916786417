import { useRef, useEffect } from 'react';

import { ReceiveSpeechBubble } from './receive-speech-bubble';
import { SendSpeechBubble } from './send-speech-bubble';
import { useChatWebSocketContext } from 'routes/chat/contexts/chat-websocket-context';
import { useSelector } from 'react-redux';

export const ChatRoomBody = () => {
  const userId = useSelector((state) => state.user.user?.id);

  const { messageList } = useChatWebSocketContext();
  const endOfMessagesRef = useRef(null);

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messageList]);
  return (
    <div className="flex flex-col h-full overflow-y-auto px-4 p-3 space-y-4">
      {messageList &&
        messageList.map((message, index) => {
          return message.sender !== userId ? (
            <div className="self-start" key={index}>
              <ReceiveSpeechBubble text={message.content} />
            </div>
          ) : (
            <div className="self-end" key={index}>
              <SendSpeechBubble text={message.content} />
            </div>
          );
        })}
      <div ref={endOfMessagesRef} />
    </div>
  );
};
