import { useState } from 'react';
import { SendIcon } from '../send-icon';
import useChatWebSocketContext from 'routes/chat/hooks/use-chat-web-socket';

export const ChatRoomFooter = ({ chatRoomId }) => {
  const { sendJsonMessage } = useChatWebSocketContext();
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setMessage(e.target.value);
  };
  const handleSubmit = () => {
    if (message.trim() === '') {
      return;
    }
    sendJsonMessage({
      message: message,
      chat_room_id: chatRoomId,
    });
    setMessage('');
  };
  return (
    <div className="flex flex-row h-[92px] w-full px-[25px] py-[14px] gap-4 items-center bg-[var(--background,#FFFFFF)]">
      <div className="flex items-center w-full h-[50px] border border-[#E3E5F2] bg-[#FFF] rounded-lg px-[12px] py-[12px] gap-[16px]">
        <input
          type="text"
          placeholder="메시지를 입력하세요."
          className="flex-1 h-full px-2 py-1 border-none bg-transparent outline-none"
          value={message}
          onChange={handleChange}
        />
        <button
          className="flex-shrink-0 p-2 rounded-lg hover:bg-[#F7F7FB]"
          onClick={handleSubmit}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
        >
          <SendIcon />
        </button>
      </div>
    </div>
  );
};
