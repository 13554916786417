import useWebSocket from 'react-use-websocket';
import { useEffect, useState, useRef } from 'react';
import { getChatRoomList, getMessageList } from 'apis/api';
import { getCookie } from 'utils/cookie';

const useChatWebSocket = () => {
  const [messageList, setMessageList] = useState([]); // 특정 채팅방 페이지에서 노출되는 메시지들
  const [message, setMessage] = useState(''); // 입력창의 메시지
  //const [chatRoomId, setChatRoomId] = useState(null); //현재 접속중인 채팅방 id
  const [chatRoomId, setChatRoomIdState] = useState(null);
  const [chatRoomList, setChatRoomList] = useState([]); // 채팅방 리스트. 

  // closure로 인해, chatRoomId가 null값으로 저장되어있으므로 최신값으로 갱신하기 위해 useRef 사용
  const chatRoomIdRef = useRef(chatRoomId); // 즉시 접근 가능한 값
  const setChatRoomId = (id) => {
    setChatRoomIdState(id);  // 상태 업데이트 (비동기)
    chatRoomIdRef.current = id;  // 즉시 업데이트 (동기)
  };

  useEffect(() => {
    const fetchData = async () => {
      if (chatRoomId !== null) {
        const data = await getMessageList(chatRoomId);
        setMessageList(Array.isArray(data) ? data : []);
      }
    };
    fetchData();
  }, [chatRoomId]);

  const socketUrl = 'ws://4.217.234.118:8000';

  const [reconnectionAttempt, setReconnectionAttempt] = useState(0);
  const maxConnectionAttempts = 4;

  const { sendJsonMessage, getWebSocket } = useWebSocket(socketUrl, {
    onOpen: async () => {
      try {
        const data = await getChatRoomList();
        setChatRoomList(data ? data : []);
        console.log('Connected!!!');
      } catch (error) {
        console.log(error);
      }
    },
    onClose: (event) => {
      if (event.code === 4001) {
        console.log('Authentication Error');
        //TODO
      }
      console.log('Close');
      setReconnectionAttempt((prevAttempt) => prevAttempt + 1);
    },
    onError: () => {
      console.log('Error!');
    },
    onMessage: async (msg) => {
      const data = JSON.parse(msg.data);
      setChatRoomId(data.chatroom);
      // closure 로 인해, chatRoomId가 null값으로 저장되어있으므로 최신값으로 갱신
      // 외부에서 chatRoomId를 변경했더라도 closure 함수는 chatRoomId를 null 값으로 기억한다.
      // console.log(`data.chatroom : ${data.chatroom}  chatRoomId : ${chatRoomIdRef.current}`)

      if (
        chatRoomList.filter((chatRoom) => chatRoom.id === chatRoomIdRef.current).length ===
        0
      ) {
        const data = await getChatRoomList();
        setChatRoomList(data ? data : []);
      }
      if (data.chatroom === chatRoomIdRef.current) {
        setMessageList((prev_msg) => [
          ...prev_msg,
          {
            id: data.id,
            sender: data.sender,
            chatroom: data.chatroom,
            content: data.content,
            timestamp: data.timestamp,
          },
        ]);
      }

      setChatRoomList((prevChatRoomList) =>
        prevChatRoomList.map((chatRoom) =>
          chatRoom.id === chatRoomIdRef.current
            ? { ...chatRoom, last_message: data.content }
            : chatRoom,
        ),
      );
    },
    shouldReconnect: (closeEvent) => {
      if (
        closeEvent.code === 4001 &&
        reconnectionAttempt >= maxConnectionAttempts
      ) {
        setReconnectionAttempt(0);
        return false;
      }
      return true;
    },
    reconnectInterval: 1000,
  });
  useEffect(() => {
    return () => {
      const webSocketInstance = getWebSocket();
      if (webSocketInstance) {
        webSocketInstance.close(); // 웹소켓 연결 해제
      }
    };
  }, [getWebSocket]);
  return {
    messageList,
    setMessageList,
    message,
    setMessage,
    chatRoomId,
    setChatRoomId,
    chatRoomList,
    setChatRoomList,
    sendJsonMessage,
  };
};
export default useChatWebSocket;
